<div
  class="border border-gray-200 bg-white rounded-md w-full px-6 py-5 flex flex-col gap-5"
>
  <span class="text-lg text-gray-900 font-semibold">
    {{ orderPrefix + "order-status" | translate | sentenceCase }}</span
  >
  <div class="grid gap-4">
    <div class="arrow-container flex gap-1">
      <span *ngFor="let tab of tabList" class="arrow {{ getTabClass(tab) }}">
        <span class="flex items-center gap-2 max-md:ml-6 font-semibold">
          <i class="{{ getTabIconResponsive(tab?.code) }}"></i>
          <span class="{{ isShowTabName(tab) ? '' : 'hidden' }} lg:inline">
            {{ tabCodePrefix + tab?.code | translate | sentenceCase }}
          </span>
        </span>
      </span>
    </div>
  </div>
  <div class="flex justify-end gap-2">
    <button
      *ngIf="
        order?.status?.status_property?.can_cancel ||
        (order?.status?.status_property?.tab ===
          statusCodeEnum.WAIT_FOR_SHIPMENT &&
          isSupperAdmin)
      "
      class="w-full md:w-auto btn btn-md btn-destructive-secondary-gray"
      (click)="openCancelDialog()"
      [disabled]="isPending || isConfirming"
    >
      {{ orderPrefix + "cancel-order" | translate | sentenceCase }}
    </button>
    <app-button
      *ngIf="order?.can_reship && !isReshipMode"
      class="!h-9 bg-blue-600 rounded-lg text-white font-semibold w-fit"
      [loading]="isLoading"
      (onClick)="onReShipOrder(order.id ?? '')"
      styleClass="border-orange-dark-600 text-orange-dark-600"
      [label]="orderPrefix + 'recreate-shipping-order' | translate"
    ></app-button>
    <button
      [disabled]="isConfirming || isPending"
      *ngIf="order?.status?.code === statusCodeEnum.SUCCESS"
      [ngClass]="isConfirming || isPending ? 'bg-blue-300' : 'bg-blue-500'"
      class="w-full md:w-auto border border-gray-200 rounded-lg px-4 bg-blue-500"
      (click)="confirmOrder()"
    >
      <span class="text-white text-sm font-medium">
        {{ actionPrefix + "confirm" | translate | sentenceCase }}
      </span>
      <i *ngIf="isConfirming" class="pi pi-spin pi-spinner order-4"></i>
    </button>
  </div>
</div>
<p-dialog
  #cancelOrder
  [header]="orderPrefix + 'cancel-order' | translate | sentenceCase"
  [(visible)]="isCancelOrderVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '1280px': '50vw', '1024px': '60vw', '768px': '80vw' }"
  [style]="{ width: '40vw', minWidth: '320px' }"
>
  <div class="grid gap-4">
    <div class="grid gap-1">
      <label>
        {{ orderDescription + "select-a-reason" | translate | sentenceCase }}
        <span class="text-error-400">*</span>
      </label>
      <p-dropdown
        styleClass="w-full"
        [options]="orderCancelReasonList"
        [(ngModel)]="selectedReason"
        optionLabel="name"
        [showClear]="true"
        [placeholder]="
          orderDescription + 'choose-your-reason' | translate | sentenceCase
        "
        appendTo="body"
      ></p-dropdown>
    </div>

    <div class="flex gap-2 justify-center md:justify-end">
      <button
        class="max-md:w-full w-auto btn btn-md btn-secondary-gray min-w-[6rem]"
        (click)="isCancelOrderVisible = false"
      >
        {{ actionPrefix + "cancel" | translate | sentenceCase }}
      </button>
      <button
        class="max-md:w-full w-auto btn btn-md btn-destructive-primary min-w-[6rem]"
        (click)="confirmCancel()"
      >
        {{ actionPrefix + "confirm" | translate | sentenceCase }}
      </button>
    </div>
  </div>
</p-dialog>
