export enum StatusCodeEnum {
  CONFIRMED = 'CONFIRMED',
  WAIT_FOR_CONFIRMATION = 'WAIT_FOR_CONFIRMATION',
  WAIT_FOR_SHIPMENT = 'WAIT_FOR_SHIPMENT',
  WAIT_FOR_SHIP = 'WAIT_FOR_SHIP',
  TO_DELIVER = 'TO_DELIVER',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  REFUND = 'REFUND',
  TO_SHIP = 'TO_SHIP',
  CANCELLED_BY_SHOP = 'CANCELLED_BY_SHOP',
  SUCCESS = 'SUCCESS',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
}

export enum TabEnum {
  ALERT = 'ALERT',
  ORDER = 'ORDER',
}

export enum OrderType {
  HOME_CONNECT = 'HOME_CONNECT',
  NORMAL = 'NORMAL',
}
