import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import {
  ApiModule as AngularCatalogApiModule,
  BASE_PATH as ANGULAR_CATALOG_PATH,
} from '@soctrip/angular-catalog-service';
import {
  ApiModule as PromotionApiModule,
  BASE_PATH as PROMOTION_PATH,
} from '@soctrip/angular-promotion-service';
import {
  ApiModule as AngularShopApiModule,
  BASE_PATH as ANGULAR_SHOP_PATH,
} from '@soctrip/angular-shop-service';

import {
  ApiModule as PaymentApiModule,
  BASE_PATH as PAYMENT_PATH,
} from '@soctrip/angular-payment-service';

import {
  ApiModule as StorageApiModule,
  BASE_PATH as STORAGE_PATH,
} from '@soctrip/angular-storage-service';

import {
  ApiModule as AdvertisingApiModule,
  BASE_PATH as ADVERTISING_PATH,
} from '@soctrip/angular-advertising-service';

import {
  ApiModule as AnalyticApiModule,
  BASE_PATH as ANALYTIC_PATH,
} from '@soctrip/angular-shop-analytic-service';
import {
  ApiModule as OrderApiModule,
  BASE_PATH as ORDER_PATH,
} from '@soctrip/angular-order-service';

import {
  ApiModule as ShipmentApiModule,
  BASE_PATH as SHIPMENT_PATH,
} from '@soctrip/angular-shipment-service';

import {
  ApiModule as ShopAnalyticApiModule,
  BASE_PATH as SHOP_ANALYTIC_PATH,
} from '@soctrip/angular-shop-analytic-service';

import {
  ApiModule as StockApiModule,
  BASE_PATH as STOCK_PATH,
} from '@soctrip/angular-stock-service';

import {
  ApiModule as TrendingApiModule,
  BASE_PATH as TRENDING_PATH,
} from '@soctrip/angular-trending-service';

import { environment } from 'src/environments/environment';
import { Locales } from './core/constants/locales.config';
import { AppSecurityModule } from '@soctrip-common/app-security';

const enum ServiceName {
  STOCK = 'STOCK',
  CATALOG = 'CATALOG',
  SHOP = 'SHOP',
  STORAGE = 'STORAGE',
  ORDER = 'ORDER',
  PROMOTION = 'PROMOTION',
  PAYMENT = 'PAYMENT',
  SHIPMENT = 'SHIPMENT',
  ADVERTISING = 'ADVERTISING',
  ANALYTIC = 'ANALYTIC',
  TRENDING = 'TRENDING',
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppSecurityModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularCatalogApiModule,
    PromotionApiModule,
    AngularShopApiModule,
    PaymentApiModule,
    StorageApiModule,
    AdvertisingApiModule,
    AnalyticApiModule,
    OrderApiModule,
    ShipmentApiModule,
    ShopAnalyticApiModule,
    StockApiModule,
    TrendingApiModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    {
      provide: ADVERTISING_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ADVERTISING]
      }`,
    },
    {
      provide: ANGULAR_CATALOG_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.CATALOG]
      }`,
    },
    {
      provide: PROMOTION_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PROMOTION]
      }`,
    },
    {
      provide: ANGULAR_SHOP_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHOP]
      }`,
    },
    {
      provide: PAYMENT_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PAYMENT]
      }`,
    },
    {
      provide: STORAGE_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.STORAGE]
      }`,
    },
    {
      provide: ANALYTIC_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ANALYTIC]
      }`,
    },
    {
      provide: ORDER_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ORDER]
      }`,
    },
    {
      provide: SHIPMENT_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHIPMENT]
      }`,
    },
    {
      provide: STOCK_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.STOCK]
      }`,
    },
    {
      provide: TRENDING_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.TRENDING]
      }`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
