import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.BAR_CHART_SQUARE_01,
  },
  {
    path: 'promotion-program',
    title: 'promotion-program',
    icon: SoctripIcons.TARGET_04,
  },
  {
    path: 'voucher-shop',
    title: 'voucher-management',
    icon: SoctripIcons.TICKET_01,
  },
  {
    path: 'voucher-v2',
    title: 'voucher-v2',
    icon: SoctripIcons.TICKET_01,
  },
  {
    path: 'order',
    title: 'order-management',
    icon: SoctripIcons.FILE_SEARCH_02,
  },
  {
    path: 'product-management',
    title: 'product-management',
    icon: SoctripIcons.TAG_02,
  },
  {
    path: 'shop-management',
    title: 'shop-management',
    icon: SoctripIcons.BUILDING_02,
  },
  {
    path: 'refund-management',
    title: 'refund-management',
    icon: SoctripIcons.SHARE_05,
  },
  {
    path: 'banner-management',
    title: 'banner-management',
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
  },
  {
    path: 'faq',
    title: 'faq-assistant',
    icon: SoctripIcons.MESSAGE_QUESTION_SQUARE,
  },
  {
    path: 'soctrip-chat',
    title: 'soctrip-chat',
    icon: SoctripIcons.MESSAGE_CHAT_SQUARE,
  },
  {
    path: 'supplier-management',
    title: 'supplier-management',
    icon: SoctripIcons.FILM_03,
  },
  {
    path: 'connect-sellers-management',
    title: 'connect-sellers-management',
    icon: SoctripIcons.DOTS_GRID,
  },
  {
    path: 'promo-section',
    title: 'promo-section',
    icon: SoctripIcons.WALLET_05,
  },
  {
    path: 'commission-fee',
    title: 'commission-fee',
    icon: SoctripIcons.BANK_NOTE_03,
  },
];
