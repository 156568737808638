import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { MessageService } from 'primeng/api';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { FileService } from 'src/app/core/services/file.service';
import { convertViToEn } from 'src/app/core/utils/convert-text.util';
import { environment } from 'src/environments/environment';
import { OrderType } from 'src/app/core/enum/order.enum';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  @Input() order: OrderDetailDTO;

  readonly SoctripIcons = SoctripIcons;
  readonly orderType = OrderType;
  orderPrefix = 'section-order-management.';

  constructor(
    private fileService: FileService,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  getTotalProduct(quantity: number, price: number) {
    return price * quantity;
  }
  getImgWebp(id: string) {
    return this.fileService.getImgWebp(id);
  }

  getCurrencyCode(): string {
    return 'USD';
  }

  onCopySuccess() {
    this.messageService.add({
      severity: 'success',
      detail: this.translator.sentenceCase(
        `${this.orderPrefix}copied-successfully`,
      ),
    });
  }
}
